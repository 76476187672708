.hs-banner>img{
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    animation: pulse 3s ease-in-out;

}
.about-banner>img{
    border-radius: 2%;
}
.contact-banner>img{
    border-radius: 2%;
    animation: pulse 2s ease-in-out;
}
.brands, .py-3, .py-md-4{
    margin: 0 !important; 
    padding: 0 !important;
}
.brands>img{
    display: flex;
    width: 2vh;
    height: 14vh;
    
}

@keyframes pulse {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }